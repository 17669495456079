// BUTI DINERS, INC. All right Reserved ©

const isLocal = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === "development";
};

const DEV_BASE_URL = "http://localhost:8000";
const PROD_BASE_URL = "https://www.skipli-external-backend.com";
const BASE_URL = isLocal() ? DEV_BASE_URL : PROD_BASE_URL;

// -------------- BUTI METHODS --------------
// GET METHODS
const CONVERT_LOCAL_TIME_TO_UTC = `${BASE_URL}/convertLocalTimeToUTC`;
const CONVERT_UTC_TIME_TO_LOCAL_TIME = `${BASE_URL}/convertUTCToLocalTime`;
const GET_CURRENT_UTC_TIMESTAMP = `${BASE_URL}/currentUTCTimestamp`;
const GET_CUSTOMER_STRIPE_RECEIPT = `${BASE_URL}/customerStripeReceipt`;
const GET_DOORDASH_DELIVERY_DETAILS = `${BASE_URL}/doordashDeliveryDetails`;
const GET_TIME_RANGE = `${BASE_URL}/timeRange`;
const SEARCH_SHOPS_FOR_MERCHANT_DASHBOARD = `${BASE_URL}/searchShopsForMerchantDashboard`;

// POST METHODS
const ADD_EMAIL_TO_MAILING_LIST = `${BASE_URL}/addEmail`;
const CHANGE_MENU_GROUP_IMAGE = `${BASE_URL}/changeMenuGroupImage`;
const CHANGE_MENU_ITEM_IMAGE = `${BASE_URL}/changeMenuItemImage`;
const CHANGE_SHOP_LOGO = `${BASE_URL}/changeShopLogo`;
const CHANGE_SHOP_THUMBNAIL_IMAGE = `${BASE_URL}/changeShopThumbnailImage`;
const GET_CUSTOMER_ITEMIZED_RECEIPT = `${BASE_URL}/customerItemizedReceipt`;
const SAVE_MERCHANT_STRIPE_ACCOUNT_ID = `${BASE_URL}/saveMerchantStripeAccountID`;
const SEND_TEXT_MESSAGE = `${BASE_URL}/sendTextMessage`;
const SEND_EMAIL = `${BASE_URL}/sendEmail`;
const UPLOAD_IMAGE_TO_AWS = `${BASE_URL}/uploadImageToAws`;

// -------------- CUSTOMER METHODS --------------
// GET METHODS
const CREATE_UNIQUE_ID_FOR_CUSTOMER = `${BASE_URL}/customerUniqueID`;
const GET_CUSTOMER_CART = `${BASE_URL}/customerCart`;
const GET_CUSTOMER_PAST_ORDERS_AT_SHOP = `${BASE_URL}/customerPastOrdersAtShop`;
const GET_CUSTOMER_PAYMENT_METHODS = `${BASE_URL}/customerPaymentMethods`;
const GET_CUSTOMER_PROFILE = `${BASE_URL}/customerProfile`;
const GET_MENU_ITEM_FULL_IMAGE = `${BASE_URL}/menuItemFullImage`;

// POST METHODS
const ADD_ORDER_TO_CUSTOMER_PAST_ORDERS = `${BASE_URL}/addOrderToCustomerPastOrders`;
const ADD_ORDER_TO_SHOP_ACTIVE_ORDERS = `${BASE_URL}/addOrderToShopActiveOrders`;
const ATTACH_PAYMENT_METHOD_TO_CUSTOMER = `${BASE_URL}/attachPaymentMethodToCustomer`;
const CHANGE_CUSTOMER_PROFILE = `${BASE_URL}/changeCustomerProfile`;
const CLEAR_CUSTOMER_CART = `${BASE_URL}/clearCustomerCart`;
const CREATE_STRIPE_CUSTOMER_ID = `${BASE_URL}/newStripeCustomerID`;
const DETACH_PAYMENT_METHOD_FROM_CUSTOMER = `${BASE_URL}/detachPaymentMethodFromCustomer`;
const SENT_EXPORT_PAYOUT_TO_MAIL = `${BASE_URL}/sent_export_payout_to_mail`;
const INITIALIZE_STRIPE_CHECKOUT = `${BASE_URL}/initializeStripeCheckout`;
const SAVE_CUSTOMER_PHONE_NUMBERS = `${BASE_URL}/saveCustomerPhoneNumbers`;

// -------------- MERCHANT METHODS --------------
// GET METHODS
const CHECK_IF_ITEMS_ARE_IN_STOCK = `${BASE_URL}/checkIfItemsAreInStock`;
const CREATE_STRIPE_CONNECT_ACCOUNT = `${BASE_URL}/createStripeConnectAccount`;
const GET_COMBINE_THIRD_PARTY_ORDERS_INFO = `${BASE_URL}/combineThirdPartyOrdersInfo`;
const GET_GROUP_MODIFIER_INFORMATION = `${BASE_URL}/groupModifierInformation`;
const GET_GUEST_CHECKIN_INFO = `${BASE_URL}/guest_checkin_info`;
const GET_GUEST_REVIEWS = `${BASE_URL}/guest_reviews`;
const GET_MENU_FROM_WOFLOW = `${BASE_URL}/menuFromWoflow`;
const GET_MENU_GROUP_INFORMATION = `${BASE_URL}/menuGroupInformation`;
const GET_MENU_ITEM_INFORMATION = `${BASE_URL}/menuItemInformation`;
const GET_QZ_DIGITAL_CERTIFICATE = `${BASE_URL}/qzDigitalCertificate`;
const GET_QZ_SIGNATURE = `${BASE_URL}/qzSignature`;
const GET_SALES_REPORT_DATA = `${BASE_URL}/salesReportData`;
const GET_MENU_MODIFIER_GROUP_INFORMATION = `${BASE_URL}/menuModifierGroupInformation`;
const GET_MENU_MODIFIER_INFORMATION = `${BASE_URL}/menuModifierInformation`;
const GET_MERCHANT_GET_PAID_INFO = `${BASE_URL}/merchantGetPaidInfo`;
const GET_ONLINE_ORDERING_AGREEMENT = `${BASE_URL}/onlineOrderingAgreement`;
const GET_PAYOUTS_FOR_CONNECTED_ACCOUNT = `${BASE_URL}/payoutsForConnectedAccount`;
const GET_PAYOUTS_FOR_CONNECTED_ACCOUNT_BY_CREATED = `${BASE_URL}/payoutsForConnectedAccountByCreated`;
const GET_PAYOUT_TRANSACTIONS = `${BASE_URL}/payoutTransactions`;
const GET_SHOP_ACTIVE_COUPONS = `${BASE_URL}/activeCoupons`;
const GET_SHOP_ACTIVE_PRINTERS = `${BASE_URL}/activePrinters`;
const GET_SHOP_ALL_GROUPS = `${BASE_URL}/allGroups`;
const GET_SHOP_ALL_ITEMS = `${BASE_URL}/allItems`;
const GET_SHOP_ALL_MENUS = `${BASE_URL}/allMenus`;
const GET_SHOP_ALL_MODIFIER_GROUPS = `${BASE_URL}/allModifierGroups`;
const GET_SHOP_ALL_MODIFIERS = `${BASE_URL}/allModifiers`;
const GET_SHOP_ALL_PERSONNELS = `${BASE_URL}/allPersonnels`;
const GET_SHOP_BASIC_INFO = `${BASE_URL}/shopBasicInfo`;
const GET_PAYMENT_INFO = `${BASE_URL}/GetUniqueCustomerPayment`;
const GET_MENUS_FOR_CUSTOMER = `${BASE_URL}/menusForCustomer`;
const GET_SHOP_ORDERS = `${BASE_URL}/shopOrders`;
const GET_SHOP_PERSONNEL_INFORMATION = `${BASE_URL}/personnelInfo`;
const GET_SHOP_WEBSITE_INFO = `${BASE_URL}/websiteInfo`;
const VERIFY_PERSONNEL_PIN = `${BASE_URL}/verifyPersonnelPin`;

// POST METHODS
const ADD_GROUP_TO_MENU = `${BASE_URL}/addGroupToMenu`;
const ADD_ITEM_TO_GROUP = `${BASE_URL}/addItemToGroup`;
const ADD_ITEM_TO_GROUP_MODIFIER = `${BASE_URL}/addItemToGroupModifier`;
const ADD_MODIFIER_TO_GROUP = `${BASE_URL}/addModifierToGroup`;
const ADD_PRINTER = `${BASE_URL}/addPrinterToShop`;
const CHANGE_STATUS_OF_ACTIVE_ORDER = `${BASE_URL}/changeStatusOfActiveOrder`;
const CHANGE_PRINTER_BRAND = `${BASE_URL}/changePrinterBrand`;
const CREATE_COUPON = `${BASE_URL}/createCoupon`;
const CREATE_NEW_ITEM = `${BASE_URL}/createNewItem`;
const CREATE_NEW_MENU_GROUP = `${BASE_URL}/createNewMenuGroup`;
const CREATE_NEW_MENU = `${BASE_URL}/createNewMenu`;
const CREATE_NEW_MODIFIER = `${BASE_URL}/createNewModifier`;
const CREATE_NEW_MODIFIER_GROUP = `${BASE_URL}/createNewModifierGroup`;
const CREATE_NEW_SHOP_PERSONNEL = `${BASE_URL}/createNewShopPersonnel`;
const CREATE_PARSEUR_MAILBOX = `${BASE_URL}/createParseurMailbox`;
const CREATE_REFUND_REQUEST = `${BASE_URL}/createRefundRequest`;
const ARCHIVE_ITEM = `${BASE_URL}/archiveItem`;
const ARCHIVE_MENU_GROUP = `${BASE_URL}/archiveMenuGroup`;
const DELETE_MENU = `${BASE_URL}/deleteMenu`;
const DELETE_MODIFIER = `${BASE_URL}/deleteModifier`;
const ARCHIVE_MODIFIER_GROUP = `${BASE_URL}/archiveModifierGroup`;
const MOVE_ACTIVE_ORDER_TO_PAST_ORDERS = `${BASE_URL}/moveActiveOrderToPastOrders`;
const REMOVE_COUPON = `${BASE_URL}/removeCoupon`;
const REMOVE_GROUP_FROM_MENU = `${BASE_URL}/removeGroupFromMenu`;
const REMOVE_ITEM_FROM_GROUP = `${BASE_URL}/removeItemFromGroup`;
const REMOVE_ITEM_FROM_GROUP_MODIFIER = `${BASE_URL}/removeItemFromGroupModifier`;
const SAVE_CHANGED_COUPON = `${BASE_URL}/saveChangedCoupon`;
const REMOVE_SHOP_PERSONNEL = `${BASE_URL}/removeShopPersonnel`;
const REMOVE_PRINTER = `${BASE_URL}/removePrinter`;
const SAVE_CHANGED_DELIVER_INFO = `${BASE_URL}/saveChangedDeliverInfo`;
const SAVE_CHANGED_GROUP_INFO = `${BASE_URL}/saveChangedGroupInfo`;
const SAVE_CHANGED_ITEM_INFO = `${BASE_URL}/saveChangedItemInfo`;
const SAVE_CHANGED_MENU_INFO = `${BASE_URL}/saveChangedMenuInfo`;
const SAVE_CHANGED_MERCHANT_GET_PAID_INFO = `${BASE_URL}/saveChangedMerchantGetPaidInfo`;
const SAVE_CHANGED_MODIFIER_INFO = `${BASE_URL}/saveChangedModifierInfo`;
const SAVE_CHANGED_MODIFIER_GROUP_INFO = `${BASE_URL}/saveChangedModifierGroupInfo`;
const SAVE_CHANGED_OPEN_HOURS = `${BASE_URL}/saveChangedOpenHours`;
const SAVE_CHANGED_PICKUP_INFO = `${BASE_URL}/saveChangedPickUpInfo`;
const SAVE_CHANGED_SHOP_INFO = `${BASE_URL}/saveChangedShopInfo`;
const SAVE_CHECK_IN_INFO = `${BASE_URL}/saveCheckInInfo`;
const SAVE_MAILBOX_FOR_COMBINE_ORDERS = `${BASE_URL}/saveMailboxForCombineOrders`;
const SAVE_NEW_ALL_GROUPS = `${BASE_URL}/saveNewAllGroups`;
const SAVE_NEW_ALL_ITEMS = `${BASE_URL}/saveNewAllItems`;
const SAVE_NEW_ALL_MODIFIER_GROUPS = `${BASE_URL}/saveNewAllModifierGroups`;
const SAVE_NEW_ALL_MODIFIERS = `${BASE_URL}/saveNewAllModifiers`;
const SAVE_PERSONNEL_INFO = `${BASE_URL}/savePersonnelInfo`;
const SAVE_SPECIAL_HOURS = `${BASE_URL}/saveSpecialHours`;
const SAVE_WEBSITE = `${BASE_URL}/saveWebsite`;
const SUBMIT_ONLINE_ORDERING_FORM = `${BASE_URL}/submitOnlineOrderingAgreement`;

export default {
  ADD_EMAIL_TO_MAILING_LIST,
  ADD_GROUP_TO_MENU,
  ADD_ITEM_TO_GROUP,
  ADD_ITEM_TO_GROUP_MODIFIER,
  ADD_MODIFIER_TO_GROUP,
  ADD_ORDER_TO_CUSTOMER_PAST_ORDERS,
  ADD_ORDER_TO_SHOP_ACTIVE_ORDERS,
  ADD_PRINTER,
  ATTACH_PAYMENT_METHOD_TO_CUSTOMER,
  CHANGE_CUSTOMER_PROFILE,
  CHANGE_MENU_GROUP_IMAGE,
  CHANGE_MENU_ITEM_IMAGE,
  CHANGE_PRINTER_BRAND,
  CHANGE_SHOP_LOGO,
  CHANGE_SHOP_THUMBNAIL_IMAGE,
  CHANGE_STATUS_OF_ACTIVE_ORDER,
  CHECK_IF_ITEMS_ARE_IN_STOCK,
  CLEAR_CUSTOMER_CART,
  CONVERT_LOCAL_TIME_TO_UTC,
  CONVERT_UTC_TIME_TO_LOCAL_TIME,
  CREATE_COUPON,
  CREATE_NEW_ITEM,
  CREATE_NEW_MENU_GROUP,
  CREATE_NEW_MENU,
  CREATE_NEW_MODIFIER,
  CREATE_NEW_MODIFIER_GROUP,
  CREATE_NEW_SHOP_PERSONNEL,
  GET_PAYMENT_INFO,
  CREATE_PARSEUR_MAILBOX,
  CREATE_REFUND_REQUEST,
  CREATE_STRIPE_CONNECT_ACCOUNT,
  CREATE_STRIPE_CUSTOMER_ID,
  CREATE_UNIQUE_ID_FOR_CUSTOMER,
  ARCHIVE_ITEM,
  ARCHIVE_MENU_GROUP,
  ARCHIVE_MODIFIER_GROUP,
  DELETE_MENU,
  DELETE_MODIFIER,
  DETACH_PAYMENT_METHOD_FROM_CUSTOMER,
  SENT_EXPORT_PAYOUT_TO_MAIL,
  GET_CUSTOMER_ITEMIZED_RECEIPT,
  GET_COMBINE_THIRD_PARTY_ORDERS_INFO,
  GET_CURRENT_UTC_TIMESTAMP,
  GET_CUSTOMER_CART,
  GET_CUSTOMER_PROFILE,
  GET_CUSTOMER_PAST_ORDERS_AT_SHOP,
  GET_CUSTOMER_PAYMENT_METHODS,
  GET_CUSTOMER_STRIPE_RECEIPT,
  GET_DOORDASH_DELIVERY_DETAILS,
  GET_GROUP_MODIFIER_INFORMATION,
  GET_GUEST_CHECKIN_INFO,
  GET_GUEST_REVIEWS,
  GET_MENU_FROM_WOFLOW,
  GET_MENU_GROUP_INFORMATION,
  GET_MENU_ITEM_FULL_IMAGE,
  GET_MENU_ITEM_INFORMATION,
  GET_MENU_MODIFIER_GROUP_INFORMATION,
  GET_MENU_MODIFIER_INFORMATION,
  GET_MENUS_FOR_CUSTOMER,
  GET_MERCHANT_GET_PAID_INFO,
  GET_PAYOUTS_FOR_CONNECTED_ACCOUNT,
  GET_PAYOUTS_FOR_CONNECTED_ACCOUNT_BY_CREATED,
  GET_PAYOUT_TRANSACTIONS,
  GET_ONLINE_ORDERING_AGREEMENT,
  GET_QZ_DIGITAL_CERTIFICATE,
  GET_QZ_SIGNATURE,
  GET_SALES_REPORT_DATA,
  GET_SHOP_ACTIVE_COUPONS,
  GET_SHOP_ACTIVE_PRINTERS,
  GET_SHOP_ALL_GROUPS,
  GET_SHOP_ALL_ITEMS,
  GET_SHOP_ALL_MENUS,
  GET_SHOP_ALL_MODIFIER_GROUPS,
  GET_SHOP_ALL_MODIFIERS,
  GET_SHOP_ALL_PERSONNELS,
  GET_SHOP_BASIC_INFO,
  GET_SHOP_ORDERS,
  GET_SHOP_PERSONNEL_INFORMATION,
  GET_SHOP_WEBSITE_INFO,
  GET_TIME_RANGE,
  INITIALIZE_STRIPE_CHECKOUT,
  MOVE_ACTIVE_ORDER_TO_PAST_ORDERS,
  REMOVE_COUPON,
  REMOVE_GROUP_FROM_MENU,
  REMOVE_ITEM_FROM_GROUP,
  REMOVE_ITEM_FROM_GROUP_MODIFIER,
  SAVE_CHANGED_COUPON,
  REMOVE_SHOP_PERSONNEL,
  REMOVE_PRINTER,
  SAVE_CHANGED_DELIVER_INFO,
  SAVE_CHANGED_GROUP_INFO,
  SAVE_CHANGED_ITEM_INFO,
  SAVE_CHANGED_MENU_INFO,
  SAVE_CHANGED_MERCHANT_GET_PAID_INFO,
  SAVE_CHANGED_MODIFIER_INFO,
  SAVE_CHANGED_MODIFIER_GROUP_INFO,
  SAVE_CHANGED_OPEN_HOURS,
  SAVE_CHANGED_PICKUP_INFO,
  SAVE_CHANGED_SHOP_INFO,
  SAVE_CUSTOMER_PHONE_NUMBERS,
  SAVE_MERCHANT_STRIPE_ACCOUNT_ID,
  SAVE_CHECK_IN_INFO,
  SAVE_MAILBOX_FOR_COMBINE_ORDERS,
  SAVE_NEW_ALL_GROUPS,
  SAVE_NEW_ALL_ITEMS,
  SAVE_NEW_ALL_MODIFIER_GROUPS,
  SAVE_NEW_ALL_MODIFIERS,
  SAVE_PERSONNEL_INFO,
  SAVE_SPECIAL_HOURS,
  SAVE_WEBSITE,
  SEARCH_SHOPS_FOR_MERCHANT_DASHBOARD,
  SEND_TEXT_MESSAGE,
  SEND_EMAIL,
  SUBMIT_ONLINE_ORDERING_FORM,
  UPLOAD_IMAGE_TO_AWS,
  VERIFY_PERSONNEL_PIN,
};
