// BUTI DINERS, INC. All right Reserved ©

import ConfirmModal from "./ConfirmModal";
import ConfirmRefundOrderModal from "./ConfirmRefundOrderModal";
import ConfirmMerchantGetPaidModal from "./ConfirmMerchantGetPaidModal";
import CustomerCheckInModal from "./CustomerCheckInModal";
import BenefitsModal from "./BenefitsModal";

// Confirm Delete Modals
import ConfirmArchiveGroupModal from "./ConfirmDeleteModals/ConfirmArchiveGroupModal";
import ConfirmCloseAllOrderModal from "./ConfirmCloseAllOrderModal";
import ConfirmCloseOrderModal from "./ConfirmCloseOrderModal";
import ConfirmArchiveItemModal from "./ConfirmDeleteModals/ConfirmArchiveItemModal";
import ConfirmDeleteMenuModal from "./ConfirmDeleteModals/ConfirmDeleteMenuModal";
import ConfirmDeleteModifierModal from "./ConfirmDeleteModals/ConfirmDeleteModifierModal";
import ConfirmArchiveModifierGroupModal from "./ConfirmDeleteModals/ConfirmArchiveModifierGroupModal";

import ImageViewerModal from "./ImageViewerModal";
import ImportMenuModal from "./ImportMenuModal";

// Menus Management Modals
import AddGroupModal from "./MenusManagementModals/AddGroupModal";
import AddItemModal from "./MenusManagementModals/AddItemModal";
import AddModifierGroupModal from "./MenusManagementModals/AddModifierGroupModal";
import AddModifierModal from "./MenusManagementModals/AddModifierModal";
import MenuGroupModal from "./MenusManagementModals/MenuGroupModal";
import MenuItemModal from "./MenusManagementModals/MenuItemModal";
import MenuModal from "./MenusManagementModals/MenuModal";
import ModifierGroupModal from "./MenusManagementModals/ModifierGroupModal";
import ModifierModal from "./MenusManagementModals/ModifierModal";

import LoadingModal from "./LoadingModal";
import NewOrdersAlarmModal from "./NewOrdersAlarmModal";
import PastOrderActionsModal from "./PastOrderActionsModal";
import PastOrdersQuickReportModal from "./PastOrdersQuickReportModal";
import PrinterInfoModal from "./PrinterInfoModal";

import SelectEstimatePrepTimeModal from "./SelectEstimatePrepTimeModal";
import SortCategoriesModal from "./SortCategoriesModal";
import WebsiteBuilderEdit from "./WebsiteBuilderEdit";

// Personnel Modals
import CreateUserModal from "./CreateUserModal";

const Modals = {
  AddGroupModal,
  AddItemModal,
  AddModifierGroupModal,
  AddModifierModal,
  BenefitsModal,
  ConfirmArchiveGroupModal,
  ConfirmArchiveItemModal,
  ConfirmArchiveModifierGroupModal,
  ConfirmCloseAllOrderModal,
  ConfirmCloseOrderModal,
  ConfirmDeleteMenuModal,
  ConfirmDeleteModifierModal,
  ConfirmMerchantGetPaidModal,
  ConfirmModal,
  ConfirmRefundOrderModal,
  CreateUserModal,
  CustomerCheckInModal,
  ImageViewerModal,
  ImportMenuModal,
  LoadingModal,
  MenuGroupModal,
  MenuItemModal,
  MenuModal,
  ModifierGroupModal,
  ModifierModal,
  NewOrdersAlarmModal,
  PastOrderActionsModal,
  PastOrdersQuickReportModal,
  PrinterInfoModal,
  SelectEstimatePrepTimeModal,
  SortCategoriesModal,
  WebsiteBuilderEdit,
};

export default Modals;
